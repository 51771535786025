import ReactGA from 'react-ga';

// Google Analytics
if (typeof window !== 'undefined') {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize('UA-173958284-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
}

const AppExtras = () => {
  return null;
};

export default AppExtras;
