/**
 * FilteredContentsView view component.
 * Customized TabularView
 * Filter by content type
 */

import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Container, Table, Label } from 'semantic-ui-react';
import { Form, Checkbox } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

// blocks
import { blocks } from '~/config';
import { getBlocks, hasBlocksData, getBaseUrl } from '@plone/volto/helpers';
import { BodyClass } from '@plone/volto/helpers';

function datetocompare(item) {
  if (item == undefined) {
    return '';
  }
  if (item.review_state === 'published') {
    return item.effective;
  } else {
    return item.created;
  }
}

function getFirstTextBlock(item) {
  const myblocks = getBlocks(item);
  if (hasBlocksData(item)) {
    let textblocks = myblocks.filter((block) => block[1]['@type'] === 'text');
    let firsttextblock = textblocks ? textblocks[0] : null;
    let block = firsttextblock;
    const Block = blocks.blocksConfig[block[1]['@type']]?.['view'] || null;
    return Block !== null ? (
      <div>
        <Block
          key={block[0]}
          id={block[0]}
          properties={item}
          data={block[1]}
          path={getBaseUrl(item?.url || '')}
        />
      </div>
    ) : (
      <div key={block[0]}>kein text block</div>
    );
  }
}

/**
 * FilteredContentsView view component function.
 * @function FilteredContentsView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const FilteredContentsView = ({ content }) => {
  const [filter, setFilter] = useState({
    portaltype: 'all',
    contentItems: content?.items
      ? content.items.sort(
          (a, b) =>
            (datetocompare(b) > datetocompare(a)) -
            (datetocompare(b) < datetocompare(a)),
        )
      : [],
  });

  const handleChangePortaltype = (event, { value }) => {
    setFilter({
      portaltype: value,
      contentItems:
        value === 'all'
          ? content.items
          : content.items.filter((item) => item['@type'] === value),
    });
  };

  return (
    <>
      <BodyClass className="filteredcontentsview" />
      <Container className="view-wrapper">
        <article id="content">
          <header>
            <h1 className="documentFirstHeading">{content.title}</h1>
            {content.description && (
              <p className="documentDescription">{content.description}</p>
            )}
          </header>
          <section id="content-core">
            <Form className="filteredContentsViewFilter">
              <Form.Field>
                <Checkbox
                  radio
                  label="alle"
                  name="portaltypeGroup"
                  value="all"
                  checked={filter.portaltype === 'all'}
                  onChange={handleChangePortaltype}
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  radio
                  label="News"
                  name="portaltypeGroup"
                  value="News Item"
                  checked={filter.portaltype === 'News Item'}
                  onChange={handleChangePortaltype}
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  radio
                  label="Events"
                  name="portaltypeGroup"
                  value="Event"
                  checked={filter.portaltype === 'Event'}
                  onChange={handleChangePortaltype}
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  radio
                  label="Publikationen"
                  name="portaltypeGroup"
                  value="publication"
                  checked={filter.portaltype === 'publication'}
                  onChange={handleChangePortaltype}
                />
              </Form.Field>
            </Form>
  
            <Table celled padded>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={4}>
                    <FormattedMessage id="Title" defaultMessage="Title" />
                  </Table.HeaderCell>
                  <Table.HeaderCell width={3}>
                    <FormattedMessage
                      id="Description"
                      defaultMessage="Description"
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell width={5}>Text</Table.HeaderCell>
                  <Table.HeaderCell width={2}>
                    <FormattedMessage id="Type" defaultMessage="Type" />
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2}>
                    veröffentlicht / erstellt
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {filter.contentItems.map((item) => (
                  <Table.Row
                    key={item.url}
                    className={item.review_state === 'private' ? 'private' : ''}
                  >
                    <Table.Cell>
                      <Link
                        to={item.url}
                        className="summary url"
                        title={item['@type']}
                      >
                        {item.title}
                      </Link>
                      {/* {item.subjects && (
                        <div>
                          {item.subjects?.map((subject) => (
                            <Label color={'orange'} key={subject} size={'tiny'}>
                              {subject}
                            </Label>
                          ))}
                        </div>
                      )} */}
                    </Table.Cell>
                    <Table.Cell>{item.description}</Table.Cell>
                    <Table.Cell>{getFirstTextBlock(item)}</Table.Cell>
                    <Table.Cell>{item['@type']}</Table.Cell>
                    <Table.Cell>
                      {item.effective
                        ? moment(item.effective).fromNow()
                        : moment(item.created).fromNow()}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </section>
        </article>
      </Container>
    </>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
FilteredContentsView.propTypes = {
  /**
   * Content of the object
   */
  content: PropTypes.shape({
    /**
     * Title of the object
     */
    title: PropTypes.string,
    /**
     * Description of the object
     */
    description: PropTypes.string,
    /**
     * Child items of the object
     */
    items: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * Title of the item
         */
        title: PropTypes.string,
        /**
         * Description of the item
         */
        description: PropTypes.string,
        /**
         * Url of the item
         */
        url: PropTypes.string,
        /**
         * Review state of the item
         */
        review_state: PropTypes.string,
        /**
         * Type of the item
         */
        '@type': PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default FilteredContentsView;
