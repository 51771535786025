import React from 'react';

import { menuitems } from '~/config.js';

const MainMenu = (props) => {
  return (
    <nav id="mainmenu" aria-label="Menu">
      <ul>
        {menuitems.map((item, index) => (
          <li key={index} className={item[0]}>
            <a
              href={'#' + item[0]}
              className={props.selectedMenuitem === item ? 'current' : ''}
              onClick={(e) => props.handleMenuClick(e, item)}
            >
              {item[1]}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default MainMenu;
