import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Image } from 'semantic-ui-react';

import { MainMenu } from '~/components';
import { closeModal } from '~/actions';

const HeaderComponent = () => {
  const [selectedMenuitem, setSelectedMenuitem] = useState('top');
  const dispatch = useDispatch();
  const handleMenuClick = (event, menuitem) => {
    dispatch(closeModal());
    setSelectedMenuitem(menuitem);
    let tgt =
      menuitem === 'top'
        ? document.getElementsByClassName('segment')[0]
        : document.getElementById(menuitem[0]);
    if (tgt) {
      let menutop = 155;
      let scrollto = tgt.offsetTop - menutop;
      tgt &&
        window.scrollTo({
          top: scrollto,
          left: 0,
          behavior: 'auto',
        });
    }
    event.preventDefault();
  };

  return (
    <>
      <a
        className="textlogo"
        href="#top"
        onClick={(e) => handleMenuClick(e, 'top')}
      >
        <h1>
          Ruth schweikert<span>.</span>
        </h1>
      </a>
      <MainMenu
        selectedMenuitem={selectedMenuitem}
        handleMenuClick={handleMenuClick}
      />
    </>
  );
};

export default HeaderComponent;
