import React, { useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { asyncConnect } from 'redux-connect';
import { useDispatch, useSelector } from 'react-redux';
import { map } from 'lodash';

import { Accordion, Container, Segment, Image } from 'semantic-ui-react';

import { Helmet } from '@plone/volto/helpers';
import { BodyClass } from '@plone/volto/helpers';

// blocks
import { blocks } from '~/config';
import { getBlocks, hasBlocksData, getBaseUrl } from '@plone/volto/helpers';

import { searchContent, getQueryStringResults } from '@plone/volto/actions';

import {
  HeaderComponent,
  AgendaListing,
  PublicationsListing,
  InfoPage,
} from '~/components';

const newsOptions = {
  portal_type: ['News Item'],
  review_state: ['published'],
  fullobjects: true,
  sort_on: 'effective',
  sort_order: 'descending',
};

const pagesOptions = {
  query: [
    {
      i: 'portal_type',
      o: 'plone.app.querystring.operation.selection.any',
      v: ['Document'],
    },
    {
      i: 'review_state',
      o: 'plone.app.querystring.operation.selection.any',
      v: ['published'],
    },
  ],
  fullobjects: true,
  sort_on: 'id',
  sort_order: true,
};

const agendaFutureOptions = {
  query: [
    {
      i: 'portal_type',
      o: 'plone.app.querystring.operation.selection.any',
      v: ['Event'],
    },
    {
      i: 'review_state',
      o: 'plone.app.querystring.operation.selection.any',
      v: ['published'],
    },
    { i: 'start', o: 'plone.app.querystring.operation.date.afterToday', v: '' },
  ],
  fullobjects: true,
  sort_on: 'start',
  sort_order: false,
};

const agendaPastOptions = {
  query: [
    {
      i: 'portal_type',
      o: 'plone.app.querystring.operation.selection.any',
      v: ['Event'],
    },
    {
      i: 'review_state',
      o: 'plone.app.querystring.operation.selection.any',
      v: ['published'],
    },
    {
      i: 'start',
      o: 'plone.app.querystring.operation.date.beforeToday',
      v: '',
    },
  ],
  fullobjects: true,
  sort_on: 'start',
  sort_order: true,
};

const FrontPageView = (props) => {
  /* News */
  const emptySet = new Set([]);
  const [activeNewsIndex, setActiveNewsIndex] = useState(emptySet);
  const [piep, setPiep] = useState(0);

  /* fetch agenda and static info pages */
  const dispatchAgendaAndStaticPages = useDispatch();
  React.useEffect(() => {
    // dispatchAgendaAndStaticPages(
    //   getQueryStringResults(
    //     '/',
    //     { ...pagesOptions, fullobjects: true },
    //     'pages',
    //   ),
    // );
    dispatchAgendaAndStaticPages(
      getQueryStringResults(
        '/',
        { ...agendaFutureOptions, fullobjects: true },
        'agendafutureevents',
      ),
    );
    dispatchAgendaAndStaticPages(
      getQueryStringResults(
        '/',
        { ...agendaPastOptions, fullobjects: true },
        'agendapastevents',
      ),
    );
  }, [dispatchAgendaAndStaticPages]);
  // const pages = useSelector(
  //   (store) => store.querystringsearch?.subrequests?.pages,
  // );
  const agendaFuture = useSelector(
    (store) => store.querystringsearch?.subrequests?.agendafutureevents,
  );
  const agendaPast = useSelector(
    (store) => store.querystringsearch?.subrequests?.agendapastevents,
  );

  const handleClickReadmore = (e, titleProps) => {
    const { index } = titleProps;
    let newActiveNewsIndex = activeNewsIndex;
    if (activeNewsIndex.has(index)) {
      newActiveNewsIndex.delete(index);
    } else {
      newActiveNewsIndex.add(index);
    }
    setActiveNewsIndex(newActiveNewsIndex);
    setPiep(titleProps);
  };

  return (
    <>
      <Helmet>
        <html lang="de" />
        <title>Ruth Schweikert</title>
        <meta name="description" content="Ruth Schweikert, Schriftstellerin" />
        <link rel="shortcut icon" href="/favicon.ico" />
        <meta name="generator" content="Volto - https://plone.org" />
        {process.env.NODE_ENV === 'production' && (
          <meta
            name="google-site-verification"
            content="6u8Y837vei50AVS2HUSX-W_7RARQHzyi_dnBa3kaKo4"
          />
        )}

        <link
          rel="preload"
          href="GT-Alpina/GT-Alpina-Fine-Standard-Regular.woff2?001"
          as="font"
          type="font/woff2"
          crossOrigin="true"
        />
        <link
          rel="preload"
          href="rohbergicons/font/rohbergicons.woff2?26256924"
          as="font"
          type="font/woff2"
          crossOrigin="true"
        />
      </Helmet>
      <BodyClass className="frontpageview" />
      <Container>
        <header className="header-wrapper-simple">
          <HeaderComponent />
        </header>

        <main>
          {props.newsAsync?.items && props.newsAsync?.items_total > 0 && (
            <Segment attached id="segment-news" className="segment-headerless">
              {props.newsItems?.items &&
                props.newsItems?.items.map((item, newsIndex) => {
                  const myblocks = getBlocks(item);
                  let teaserblocks = [];
                  let readmoreblocks = [];
                  let listtopushto = teaserblocks;
                  for (let i = 0; i < myblocks.length; i++) {
                    let blc = myblocks[i];
                    listtopushto.push(blc);
                    if (blc[1].readmore) {
                      listtopushto = readmoreblocks;
                    }
                  }
                  return (
                    <Segment basic key={item['@id']} clearing>
                      <h2>{item.title}</h2>
                      {item.image !== null && (
                        <div className="leadimagewrapper">
                          <Image
                            floated="left"
                            alt={
                              item.image_caption
                                ? item.image_caption
                                : item.title
                            }
                            src={item.image?.scales.large.download}
                          />
                          {item.image_caption && (
                            <div className="leadimagecaption">
                              {item.image_caption}
                            </div>
                          )}
                        </div>
                      )}
                      {hasBlocksData(item) ? (
                        <>
                          {map(teaserblocks.slice(1), (block) => {
                            const Block =
                              blocks.blocksConfig[block[1]['@type']]?.[
                                'view'
                              ] || null;
                            return Block !== null ? (
                              <Block
                                key={block[0]}
                                id={block[0]}
                                properties={item}
                                data={block[1]}
                                path={getBaseUrl(
                                  props.location?.pathname || '',
                                )}
                              />
                            ) : (
                              <div key={block[0]}></div>
                            );
                          })}
                          <Accordion fluid={true} exclusive={false}>
                            <Accordion.Title
                              active={activeNewsIndex.has(newsIndex)}
                              index={newsIndex}
                              onClick={handleClickReadmore}
                            >
                              {readmoreblocks.length > 0 && (
                                <span className="readmore">weiterlesen</span>
                              )}
                            </Accordion.Title>
                            <Accordion.Content
                              active={activeNewsIndex.has(newsIndex)}
                            >
                              {map(readmoreblocks, (block) => {
                                const Block =
                                  blocks.blocksConfig[block[1]['@type']]?.[
                                    'view'
                                  ] || null;
                                return Block !== null ? (
                                  <Block
                                    key={block[0]}
                                    id={block[0]}
                                    properties={item}
                                    data={block[1]}
                                    path={getBaseUrl(
                                      props.location?.pathname || '',
                                    )}
                                  />
                                ) : (
                                  <div key={block[0]}></div>
                                );
                              })}
                            </Accordion.Content>
                          </Accordion>
                        </>
                      ) : null}
                    </Segment>
                  );
                })}
            </Segment>
          )}
          <Segment attached id="segment-publications">
            <PublicationsListing />
          </Segment>

          {props.infoPagesAsync?.items &&
            props.infoPagesAsync?.items_total > 0 && (
              <InfoPage pages={props.infoPages} requestedid={'engagement'} />
            )}

          {(agendaFuture?.items || agendaPast?.items) && (
            <Segment attached id="segment-agenda">
              {agendaFuture?.items && (
                <>
                  <AgendaListing agenda={agendaFuture} />
                  <div className="horizontalspacer" />
                </>
              )}
              {agendaFuture?.items && (
                <>
                  <h2 className="subtitle">Vergangene Events</h2>
                  <AgendaListing agenda={agendaPast} />
                </>
              )}
            </Segment>
          )}

          {props.infoPagesAsync?.items &&
            props.infoPagesAsync?.items_total > 0 && (
              <InfoPage pages={props.infoPages} requestedid={'bio'} />
            )}

          {props.infoPagesAsync?.items &&
            props.infoPagesAsync?.items_total > 0 && (
              <InfoPage pages={props.infoPages} requestedid={'anfragen'} />
            )}
        </main>
      </Container>
    </>
  );
};

export default compose(
  connect(
    (state, props) => ({
      newsItems: state.search?.subrequests?.news,
      infoPages: state.querystringsearch?.subrequests?.pages,
    }),
    { getQueryStringResults },
  ),
  asyncConnect([
    {
      key: 'newsAsync',
      promise: ({ store: { dispatch } }) =>
        dispatch(searchContent('/', newsOptions, 'news')),
    },
    {
      key: 'infoPagesAsync',
      promise: ({ store: { dispatch } }) =>
        dispatch(getQueryStringResults('/', pagesOptions, 'pages')),
    },
  ]),
)(FrontPageView);
