/**
 * Routes.
 * @module routes
 */

import { App, History } from '@plone/volto/components';
import { defaultRoutes } from '@plone/volto/routes';

import { FrontPageView, PiepView } from './components';

/**
 * Routes array.
 * @array
 * @returns {array} Routes.
 */
const routes = [
  {
    path: '/',
    component: FrontPageView, // Change this if you want a different component
    exact: true,
  },
  // Add your routes here
  // {
  //   path: '/piep',
  //   component: PiepView,
  // },

  {
    path: '/**',
    component: App,
    routes: [
      // addon routes have a higher priority then default routes
      // ...(addonRoutes || []),
      ...defaultRoutes,
    ],
  },
];

export default routes;
