import { OPEN_MODAL, CLOSE_MODAL } from '~/constants/ActionTypes';

const initialState = {
  modal: false,
};

export default function modal(state = initialState, action) {
  switch (action.type) {
    case CLOSE_MODAL:
      return {
        ...state,
        modal: false,
      };
    case OPEN_MODAL:
      return {
        ...state,
        modal: true,
      };
    default:
      return state;
  }
};