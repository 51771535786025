import BlockSettingsSchema from '@plone/volto/components/manage/Blocks/Block/Schema';

const Schema = {
  ...BlockSettingsSchema,
  fieldsets: [
    {
      ...BlockSettingsSchema.fieldsets[0],
      fields: ['readmore'],
    },
  ],
  properties: {
    readmore: {
      title: 'Weiterlesen',
      description: 'Nachfolgenden Text erst auf Click anzeigen',
      type: 'boolean',
    },
  },
};

export default Schema;
