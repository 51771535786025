import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { map } from 'lodash';

import { blocks } from '~/config';

import {
  getBlocksFieldname,
  getBlocksLayoutFieldname,
  hasBlocksData,
  getBaseUrl,
} from '@plone/volto/helpers';

const FullViewTemplate = ({ items, isEditMode }) => {
  return (
    <div className={cx('fullview-template', { 'public-ui': isEditMode })}>
      {items.map((content, index) => {
        const blocksFieldname = getBlocksFieldname(content);
        const blocksLayoutFieldname = getBlocksLayoutFieldname(content);
        return (
          <article className="fullviewitem" key={index}>
            {hasBlocksData(content) &&
              map(content[blocksLayoutFieldname].items, (block) => {
                const Block =
                  blocks.blocksConfig[
                    content[blocksFieldname]?.[block]?.['@type']]?.['view'] || null;
                return Block !== null ? (
                  <Block
                    key={block}
                    id={block}
                    properties={content}
                    data={content[blocksFieldname][block]}
                    path={getBaseUrl(location?.pathname || '')}
                  />
                ) : (
                  <div key={block}></div>
                );
              })}
          </article>
        );
      })}
    </div>
  );
};

FullViewTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
  title: PropTypes.string,
};

export default FullViewTemplate;
