import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useIntl, defineMessages, injectIntl } from 'react-intl';
import moment from 'moment';

import { Accordion, Icon } from 'semantic-ui-react';

import {
  When,
  Recurrence,
} from '@plone/volto/components/theme/View/EventDatesInfo';

import { map } from 'lodash';

import { blocks } from '~/config';

import {
  getBlocksFieldname,
  getBlocksLayoutFieldname,
  hasBlocksData,
  getBaseUrl,
} from '@plone/volto/helpers';

const EventsTemplate = ({ items, isEditMode, title, linkMore }) => {
  const intl = useIntl();
  moment.locale(intl.locale);
  // moment.locale('de');
  moment.updateLocale('de', {
    months: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ],
  });

  const [activeIndex, setActiveIndex] = useState(-1);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    // const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };

  return (
    <div className={cx('events-template', { 'public-ui': isEditMode })}>
      <Accordion duration={1000}>
        {items.map((item, index) => {
          let content = item;
          const blocksFieldname = getBlocksFieldname(content);
          const blocksLayoutFieldname = getBlocksLayoutFieldname(content);
          let date_item = moment(item.start).subtract(2, 'hours');
          let date_current = moment();
          let year_item =
            date_current.year() === date_item.year()
              ? ''
              : moment(item.start).year();
          let time_item = item.whole_day ? '' : date_item.format('HH:mm');
          let month_item =
            index > 0
              ? date_item.format('MMMM') !==
                  moment(items[index - 1].start).format('MMMM') &&
                date_item.format('MMMM')
              : date_item.format('MMMM');
          return (
            <>
              {month_item && (
                <div className="monthtitle">
                  {month_item + ' ' + date_item.year()}
                </div>
              )}

              <div
                key={index}
                className={
                  activeIndex === index ? 'event-wrapper active' : 'event-wrapper'
                }
              >
                <Accordion.Title
                  active={activeIndex === index}
                  index={index}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                  <p>
                    {date_item.format('DD. MMMM')} {year_item}{' '}
                    {time_item && <span className="time">{time_item}</span>}
                  </p>

                  <p class="event-name">{item.title}</p>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === index}>
                  {hasBlocksData(content) &&
                    map(content[blocksLayoutFieldname].items, (block) => {
                      const Block =
                        blocks.blocksConfig[
                          content[blocksFieldname]?.[block]?.['@type']
                        ]?.['view'] || null;
                      return Block !== null ? (
                        <Block
                          key={block}
                          id={block}
                          properties={content}
                          data={content[blocksFieldname][block]}
                          path={getBaseUrl(location?.pathname || '')}
                        />
                      ) : (
                        <div key={block}></div>
                      );
                    })}
                </Accordion.Content>
              </div>
            </>
          );
        })}
      </Accordion>
    </div>
  );
};

EventsTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
  title: PropTypes.string,
};

export default EventsTemplate;
