/* listing of publications component for front page */
import React, { useState } from 'react';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Segment, Menu, Accordion, Image } from 'semantic-ui-react';

import { getQueryStringResults } from '@plone/volto/actions';
import { getBlocks, hasBlocksData, getBaseUrl } from '@plone/volto/helpers';

import { blocks } from '~/config';
import { ThumbnailTemplate } from '~/components';

const publicationsOptions = {
  query: [
    {
      i: 'portal_type',
      o: 'plone.app.querystring.operation.selection.any',
      v: ['publication'],
    },
    {
      i: 'review_state',
      o: 'plone.app.querystring.operation.selection.any',
      v: ['published'],
    },
  ],
  fullobjects: true,
  sort_on: 'effective',
  sort_order: true,
};

const PublicationsListingBody = ({ publications }) => {
  const emptySet = new Set([]);
  const [activeNewsIndex, setActiveNewsIndex] = useState(emptySet);
  const [piep, setPiep] = useState(0);

  const maxCategories = [
    'Prosa',
    'Kolumnen',
    'Bühne/Film',
    'Essays',
    'Weitere Texte',
  ];
  let existingCategories = [];
  publications.items.forEach((item) => {
    existingCategories.push(...item.subjects);
  });
  existingCategories = [...new Set(existingCategories)];
  const categories = maxCategories.filter(
    (el) => existingCategories.indexOf(el) > -1,
  );
  const [state, setState] = useState({
    activeItem: categories[0],
    selectedPublications:
      publications?.items.length > 0
        ? publications.items.filter(
            (el) => el.subjects.indexOf(categories[0]) > -1,
          )
        : [],
  });

  const handleItemClick = (e, { name }) => {
    setState({
      activeItem: name,
      selectedPublications: publications.items.filter(
        (el) => el.subjects.indexOf(name) > -1,
      ),
    });
  };

  const handleClickReadmore = (e, titleProps) => {
    const { index } = titleProps;
    let newActiveNewsIndex = activeNewsIndex;
    if (activeNewsIndex.has(index)) {
      newActiveNewsIndex.delete(index);
    } else {
      newActiveNewsIndex.add(index);
    }
    setActiveNewsIndex(newActiveNewsIndex);
    setPiep(titleProps);
  };

  return (
    <>
      <Menu secondary stackable className="publications-menu">
        {categories.map((ctg) => {
          return (
            <Menu.Item
              name={ctg}
              active={state.activeItem === ctg}
              content={ctg}
              onClick={handleItemClick}
              key={ctg}
            />
          );
        })}
      </Menu>
      <>
        {state.activeItem !== 'Prosa' ? (
          state.selectedPublications.map((item, newsIndex) => {
            const myblocks = getBlocks(item);
            let teaserblocks = [];
            let readmoreblocks = [];
            let listtopushto = teaserblocks;
            for (let i = 0; i < myblocks.length; i++) {
              let blc = myblocks[i];
              listtopushto.push(blc);
              if (blc[1].readmore) {
                listtopushto = readmoreblocks;
              }
            }

            return (
              <Segment basic key={item['@id']} clearing>
                <h2>{item.title}</h2>
                {item.image !== null && (
                  <div className="leadimagewrapper">
                    <Image
                      floated="left"
                      alt={item.image_caption ? item.image_caption : item.title}
                      src={item.image?.scales.large.download}
                    />
                    {item.image_caption && (
                      <div className="leadimagecaption">
                        {item.image_caption}
                      </div>
                    )}
                  </div>
                )}
                {hasBlocksData(item) ? (
                  <>
                    {map(teaserblocks.slice(1), (block) => {
                      const Block =
                        blocks.blocksConfig[block[1]['@type']]?.['view'] ||
                        null;
                      return Block !== null ? (
                        <Block
                          key={block[0]}
                          id={block[0]}
                          properties={item}
                          data={block[1]}
                          path={getBaseUrl(item['@id'] || '')}
                        />
                      ) : (
                        <div key={block[0]}></div>
                      );
                    })}
                    <Accordion fluid={true} exclusive={false}>
                      <Accordion.Title
                        active={activeNewsIndex.has(newsIndex)}
                        index={newsIndex}
                        onClick={handleClickReadmore}
                      >
                        {readmoreblocks.length > 0 && (
                          <span className="readmore">weiterlesen</span>
                        )}
                      </Accordion.Title>
                      <Accordion.Content
                        active={activeNewsIndex.has(newsIndex)}
                      >
                        {map(readmoreblocks, (block) => {
                          const Block =
                            blocks.blocksConfig[block[1]['@type']]?.['view'] ||
                            null;
                          return Block !== null ? (
                            <Block
                              key={block[0]}
                              id={block[0]}
                              properties={item}
                              data={block[1]}
                              path={getBaseUrl(item['@id'] || '')}
                            />
                          ) : (
                            <div key={block[0]}></div>
                          );
                        })}
                      </Accordion.Content>
                    </Accordion>
                  </>
                ) : null}
              </Segment>
            );
          })
        ) : (
          <ThumbnailTemplate items={state.selectedPublications} />
        )}
      </>
    </>
  );
};

const PublicationsListing = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(
      getQueryStringResults(
        '/',
        { ...publicationsOptions, fullobjects: true },
        'publications',
      ),
    );
  }, [dispatch]);
  const publications = useSelector(
    (store) => store.querystringsearch?.subrequests?.publications || {},
  );

  return publications && publications.items?.length > 0 ? (
    <PublicationsListingBody publications={publications} />
  ) : null;
};
export default PublicationsListing;
