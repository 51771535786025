/**
 * Add your config changes here.
 * @module config
 * @example
 * export const settings = {
 *   ...defaultSettings,
 *   port: 4300,
 *   listBlockTypes: {
 *     ...defaultSettings.listBlockTypes,
 *     'my-list-item',
 *   }
 * }
 */
import React from 'react';
import createInlineStyleButton from 'draft-js-buttons/lib/utils/createInlineStyleButton';
import Icon from '@plone/volto/components/theme/Icon/Icon';
import radiodisabledSVG from '@plone/volto/icons/radio-disabled.svg';
import underlineSVG from '@plone/volto/icons/underline.svg';

import { FilteredContentsView, DefaultViewLeadimage } from '~/components';
import {
  FullViewTemplate,
  ThumbnailTemplate,
  EventsTemplate,
} from '~/components';

import {
  settings as defaultSettings,
  views as defaultViews,
  widgets as defaultWidgets,
  blocks as defaultBlocks,
  addonReducers as defaultAddonReducers,
  addonRoutes as defaultAddonRoutes,
} from '@plone/volto/config';

/**
 * Menu items displayed on front-page menu
 */
export const menuitems = [
  ['segment-publications', 'werk'],
  // ['segment-engagement', 'engagement'],
  ['segment-agenda', 'agenda'],
  ['segment-bio', 'über mich'],
  ['segment-anfragen', 'kontakt'],
];

const customBlocks = {
  listing: {
    ...defaultBlocks.blocksConfig.listing,
    templates: {
      ...defaultBlocks.blocksConfig.listing.templates,
      fullviewTemplate: {
        label: 'Full View',
        template: FullViewTemplate,
      },
      thumbnailTemplate: {
        label: 'Thumbnails',
        template: ThumbnailTemplate,
      },
      eventsTemplate: {
        label: 'Events',
        template: EventsTemplate,
      },
    },
  },
};

const customizedBlocks = {
  text: {
    ...defaultBlocks.blocksConfig.text,
    sidebarTab: 1,
  },
};

const DiscreetButton = createInlineStyleButton({
  style: 'DISCREET',
  children: <Icon name={radiodisabledSVG} size="24px" />,
});

// Custom inline (not block) styles
const customInline = {
  DISCREET: (children, { key }) => (
    <span key={key} className="discreet">
      {children}
    </span>
  ),
};

const UnderlineButton = createInlineStyleButton({
  style: 'UNDERLINE',
  children: <Icon name={underlineSVG} size="24px" />,
});

let newbuttons = defaultSettings.richTextEditorInlineToolbarButtons;
newbuttons.splice(2, 0, DiscreetButton);
newbuttons.splice(2, 0, UnderlineButton);
export const settings = {
  ...defaultSettings,
  richTextEditorInlineToolbarButtons: newbuttons,
  ToHTMLRenderers: {
    ...defaultSettings.ToHTMLRenderers,
    inline: {
      ...defaultSettings.ToHTMLRenderers.inline,
      ...customInline,
    },
  },
};

export const views = {
  ...defaultViews,
  layoutViews: {
    ...defaultViews.layoutViews,
    filteredcontents_view: FilteredContentsView,
  },
  contentTypesViews: {
    ...defaultViews.contentTypesViews,
    // frontpage: FrontPageView,
    'News Item': DefaultViewLeadimage,
    publication: DefaultViewLeadimage,
    Document: DefaultViewLeadimage,
  },
};

export const widgets = {
  ...defaultWidgets,
};

export const blocks = {
  ...defaultBlocks,
  blocksConfig: {
    ...defaultBlocks.blocksConfig,
    ...customBlocks,
    ...customizedBlocks,
  },
};

export const addonRoutes = [...defaultAddonRoutes];
export const addonReducers = { ...defaultAddonReducers };
