import React from 'react';

import { map } from 'lodash';

import { Segment, Image } from 'semantic-ui-react';

// blocks
import { blocks } from '~/config';
import { getBlocks, hasBlocksData, getBaseUrl } from '@plone/volto/helpers';

function getStaticPage(pages, requestedid) {
  return (pages?.items ?? []).find(page => page.id === requestedid);
}

/**
 * info page
 * like "über mich" or "contact / anfragen"
 */

const InfoPage = ({ pages, requestedid }) => {
  React.useEffect(() => {
    // links in Engagement links vom Text anzeigen
    // Todo nur für Desktop!
    if (requestedid === 'engagement') {
      const segmentEngagement = document.getElementById('segment-engagement');
      const anchors = segmentEngagement?.getElementsByTagName('a') || [];

      let anchs = Array.from(anchors);
      anchs = anchs.filter((el) => el.className.indexOf('sidelink') === -1);
      let l = anchs.length;

      for (let i = 0; i < l; i++) {
        const anch = anchs[i];
        let parent = anch.parentElement;
        parent.style.position = 'relative';

        let anchN = anch.cloneNode(true);
        anch.classList.add('sidelink');
        anch.after(anchN);
        anchN.style.position = 'absolute';
        // const offset = '100px'; // calc((100% - 575px)/2 - 200px);
        anchN.style.left = '-200px';
        anchN.style.display = 'inline-block';
        anchN.style.width = '200px';
      }
    }
  }, [requestedid]);

  if (requestedid == undefined) {
    return <div></div>;
  }

  let item = getStaticPage(pages, requestedid);
  if (!item) {
    return <div></div>;
  }
  return (
    <Segment attached id={'segment-' + requestedid}>
      <h2>{item.title}</h2>
      {item.image !== null && (
        <div className="leadimagewrapper">
          <Image
            floated="left"
            alt={item.image_caption ? item.image_caption : item.title}
            src={item.image?.scales.large.download}
          />
          {item.image_caption && (
            <div className="leadimagecaption">{item.image_caption}</div>
          )}
        </div>
      )}
      {hasBlocksData(item) &&
        map(getBlocks(item).slice(1), (block) => {
          const Block =
            blocks.blocksConfig[block[1]['@type']]?.['view'] || null;
          return Block !== null ? (
            <Block
              key={block[0]}
              id={block[0]}
              properties={item}
              data={block[1]}
              path={item['@id']}
            />
          ) : (
            <div key={block[0]}></div>
          );
        })}
    </Segment>
  );
};
export default InfoPage;
