import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useIntl, defineMessages, injectIntl } from 'react-intl';
import moment from 'moment';

import { Image, Popup } from 'semantic-ui-react';

const ThumbnailTemplate = ({ items, isEditMode=false, title, linkMore }) => {
  return (
    <div className={cx('thumbnail-template', { 'public-ui': isEditMode })}>
      {items.map((item, index) => {
        return (
          <a
            className="thumbnail-wrapper"
            key={index}
            href={item.event_url}
            target="_blank"
            rel="noreferrer"
          >
            <Popup
              trigger={
                <Image
                  alt={item.image_caption ? item.image_caption : item.title}
                  src={item.image?.scales.large.download || '/ruth_177_270.jpg'}
                />
              }
              position="top center"
              content={item.description || item.title}
              size="tiny"
            />
            {true && <p>{item.body || ''}</p>}
          </a>
        );
      })}
    </div>
  );
};

ThumbnailTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
  title: PropTypes.string,
};

export default ThumbnailTemplate;
