import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import moment from 'moment';

import { Icon, Modal } from 'semantic-ui-react';

import { openModal, closeModal } from '~/actions';

import { map } from 'lodash';

import { blocks } from '~/config';

import {
  getBlocksFieldname,
  getBlocksLayoutFieldname,
  hasBlocksData,
  getBaseUrl,
} from '@plone/volto/helpers';

const AgendaListing = ({ agenda = {} }) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const modal = useSelector((store) => store.modal);
  const dispatch = useDispatch();
  const intl = useIntl();
  moment.locale(intl.locale);
  // moment.locale('de');
  moment.updateLocale('de', {
    months: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ],
  });

  return agenda?.total > 0 ? (
    <div className="agendaListing">
      {agenda?.items &&
        agenda.items.map((item, index) => {
          let content = item;
          const blocksFieldname = getBlocksFieldname(content);
          const blocksLayoutFieldname = getBlocksLayoutFieldname(content);
          let date_item = moment(item.start).subtract(2, 'hours');
          let date_current = moment();
          let year_item =
            date_current.year() === date_item.year()
              ? ''
              : moment(item.start).year();
          let time_item = item.whole_day ? '' : date_item.format('HH.mm') + 'h';
          let month_item =
            index > 0
              ? date_item.format('MMMM') !==
                  moment(agenda.items[index - 1].start).format('MMMM') &&
                date_item.format('MMMM')
              : date_item.format('MMMM');
          return (
            <section key={index}>
              {month_item && (
                <div className="monthtitle">
                  {month_item + ' ' + date_item.year()}
                </div>
              )}

              <Modal
                className="container event"
                key={'event-' + index.toString()}
                onClose={() => {
                  setActiveIndex(-1);
                  dispatch(closeModal());
                }}
                onOpen={() => {
                  setActiveIndex(index);
                  dispatch(openModal());
                }}
                open={modal.modal && activeIndex === index}
                trigger={
                  <div
                    key={index}
                    className={
                      modal.modal && activeIndex === index
                        ? 'event-wrapper active'
                        : 'event-wrapper'
                    }
                  >
                    <div className="title">
                      <p>
                        {date_item.format('DD. MMMM')} {year_item}{' '}
                        {time_item && (
                          <>
                            <br />
                            <span className="time">{time_item}</span>
                          </>
                        )}
                      </p>
                      <p className="event-name">{item.title}</p>
                    </div>
                  </div>
                }
              >
                <Modal.Content>
                  <div className="title">
                    <p>
                      {date_item.format('DD. MMMM')} {year_item}{' '}
                      {time_item && (
                        <>
                          <span className="time">{time_item}</span>
                        </>
                      )}
                    </p>
                    <p className="event-name">{item.title}</p>
                  </div>
                  {hasBlocksData(content) &&
                    map(
                      content[blocksLayoutFieldname].items.slice(1),
                      (block) => {
                        const Block =
                          blocks.blocksConfig[
                            content[blocksFieldname]?.[block]?.['@type']
                          ]?.['view'] || null;
                        return Block !== null ? (
                          <Block
                            key={block}
                            id={block}
                            properties={content}
                            data={content[blocksFieldname][block]}
                            path={getBaseUrl(item?.url || '')}
                          />
                        ) : (
                          <div key={block}></div>
                        );
                      },
                    )}
                </Modal.Content>
                <Modal.Actions>
                  <Icon
                    name="close"
                    onClick={() => {
                      setActiveIndex(-1);
                      dispatch(closeModal());
                    }}
                  />
                </Modal.Actions>
              </Modal>
            </section>
          );
        })}
    </div>
  ) : null;
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
AgendaListing.propTypes = {
  /**
   * Content of the object
   */
  agenda: PropTypes.shape({
    /**
     * total of items
     */
    total: PropTypes.number,
    /**
     * Child items of the object
     */
    items: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * id and path of the item
         */
        '@id': PropTypes.string,
        /**
         * Type of the item
         */
        '@type': PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default AgendaListing;
